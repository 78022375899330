<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <app-breadcrumb :breadcrumbs="breadcrumbs"/>
      <div class="content-body">
        <div class="list-view product-checkout">
          <b-card no-body>
            <b-card-header class="flex-column align-items-start">
              <b-card-title>Информация о доставке</b-card-title>
              <b-card-text class="text-muted mt-25">Введите детали доставки, комментарии</b-card-text>
            </b-card-header>
            <b-card-body>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="d-block">Транспортная компания</label>
                    <input class="form-control" v-model="addressDetails.logistics_company">
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="d-block">Номер транспортной накладной</label>
                    <input class="form-control" v-model="addressDetails.ttn">
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label class="d-block">Сумма наложки</label>
                    <input class="form-control" v-model="addressDetails.client_price">
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label class="d-block">Номер заказа клиента</label>
                    <input class="form-control" v-model="addressDetails.client_order">
                  </div>
                </div>
                <div class="col-12 col-md-5">
                  <div class="form-group">
                    <label class="d-block">Телефон получателя</label>
                    <input class="form-control" v-model="addressDetails.phone">
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="d-block">Имя получателя</label>
                    <input class="form-control" v-model="addressDetails.name">
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="d-block">Фамилия получателя</label>
                    <input class="form-control" v-model="addressDetails.surname">
                  </div>
                </div>
                <div class="col-12 col-md-8">
                  <div class="form-group">
                    <label class="d-block">Улица</label>
                    <input class="form-control" v-model="addressDetails.street">
                  </div>
                </div>
                <div class="col-12 col-md-2">
                  <div class="form-group">
                    <label class="d-block">Дом</label>
                    <input class="form-control" v-model="addressDetails.street">
                  </div>
                </div>
                <div class="col-12 col-md-2">
                  <div class="form-group">
                    <label class="d-block">Квартира</label>
                    <input class="form-control" v-model="addressDetails.street">
                  </div>
                </div>
                <div class="col-12 col-md-8">
                  <label class="d-block">Город получателя</label>
                  <input class="form-control" v-model="addressDetails.city">
                </div>
                <div class="col-12 col-md-4">
                  <label class="d-block">Индекс</label>
                  <input class="form-control" v-model="addressDetails.zip">
                </div>
                <div class="col-12">
                  <label class="d-block">Комментарии к заказу</label>
                  <textarea class="form-control" v-model="addressDetails.comments"></textarea>
                </div>
              </div>
            </b-card-body>
          </b-card>
          <div class="checkout-options">
            <b-card>
              <div class="price-details">
                <h6 class="price-title">Детали заказа</h6>
                <ul class="list-unstyled">
                  <li class="price-detail">
                    <div class="detail-title">Количество товаров</div>
                    <div class="detail-amt">{{ totalQty }} шт</div>
                  </li>
                  <li class="price-detail">
                    <div class="detail-title">Вес товаров</div>
                    <div class="detail-amt">{{ totalWeight/1000 }} кг</div>
                  </li>
                </ul>
                <hr>
                <ul class="list-unstyled">
                  <li class="price-detail">
                    <div class="detail-title detail-total">Итого</div>
                    <div class="detail-amt font-weight-bolder">{{ totalAmount }} грн</div>
                  </li>
                </ul>
                <b-button variant="primary" block @click="createOrder">Разместить заказ</b-button>
              </div>
            </b-card>
          </div>
        </div>
        <div class="list-view product-checkout mt-0">
          <div class="checkout-items">
            <b-card>
              <b-card-body>
                <p class="cart-text">
                  Кнопка &nbsp;&nbsp;
                  <b-button variant="light" class="remove-wishlist">
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span>Удалить</span>
                  </b-button>
                  &nbsp;&nbsp;чтобы оставить в корзине товары не добавляя их в заказ.
                </p>
                <p class="cart-text">
                  Кнопка &nbsp;&nbsp;
                  <b-button variant="primary" class="remove-wishlist">
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span>Добавить</span>
                  </b-button>
                  &nbsp;&nbsp; чтобы добавить товар в заказ из корзины.
                </p>
                <p class="cart-text">
                  Кнопка &nbsp;&nbsp;
                  <b-button variant="danger" class="remove-wishlist">
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span>Удалить из корзины</span>
                  </b-button>
                  &nbsp;&nbsp; чтобы удалить товар из корзины.
                </p>
              </b-card-body>
            </b-card>
            <CheckoutItem
              v-for="item in info.items"
              :key="item.id"
              :item="item"
              :items="order.items"
              @removeFromOrder="removeFromOrder"
              @addToOrder="addToOrder"
              @changeQty="changeQty"
              @removeFromCart="removeFromCart"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BButton,
} from 'bootstrap-vue';
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import CheckoutItem from '@/views/cart/CheckoutItem.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    AppBreadcrumb,
    CheckoutItem,
  },
  data() {
    return {
      info: {
        totalAmount: 0,
        totalQty: 0,
        items: [],
      },
      order: {
         items: [],
      },
      breadcrumbs: [],
      addressDetails: {
        ttn: null,
        logistics_company: null,
        client_price: null,
        client_order: null,
        name: null,
        surname: null,
        phone: null,
        street: null,
        building: null,
        flat: null,
        city: null,
        zip: null,
        comments: null,
      },
    };
  },
  async mounted() {
    await this.getCartInfo();
    this.order.items = this.info.items;
  },
  methods: {
    async changeQty(orderItemId, qty) {
      try {
        const response = await this.$api.cart.changeQty(orderItemId, { qty });
        this.info.items.find((item) => item.id === orderItemId).qty = response.data.qty;
        this.$bvToast.toast(response.data.message, {
          title: 'Изменение количества в корзине',
          variant: response.data.type,
          solid: true,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getCartInfo() {
      this.loading = true;
      this.info = (await this.$api.cart.getInfo()).data;
      this.loading = false;
    },
    removeFromOrder(orderItemId) {
      this.order.items = this.order.items.filter((item) => item.id !== orderItemId);
    },
    async addToOrder(orderItem) {
      this.order.items.push(orderItem);
    },
    async removeFromCart(orderItemId) {
      try {
        this.$api.cart.removeItem(orderItemId)
            .then(async () => {
              const itemIndex = this.info.items.findIndex((p) => p.id === orderItemId);
              this.info.items.splice(itemIndex, 1);
              this.removeFromOrder(orderItemId);
              await this.getCartInfo();
            });
      } catch (e) {
        console.log(e);
      }
    },
    async createOrder() {
      const order = {
        items: this.order.items.map(({ id }) => id),
        addressDetails: this.addressDetails,
      };
      try {
        await this.$api.cart.createOrder(order);
        await this.$router.push({ name: 'order-list' });
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    totalAmount() {
      return this.order.items.reduce((acc, item) => parseInt(acc, 10) + parseInt(item.cost, 10), 0);
    },
    totalQty() {
      return this.order.items.reduce((acc, item) => parseInt(acc, 10) + parseInt(item.qty, 10), 0);
    },
    totalWeight() {
      return this.order.items.reduce((acc, item) => parseInt(acc, 10) + parseInt(item.product_specification.weight, 10), 0);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
.details-title {
  font-size: 11px;
}
</style>
