<template>
  <b-card class="ecommerce-card" no-body>
    <div class="item-img">
      <b-link>
        <b-img
          v-if="item.small_main_image"
          :src="`/img/product/${item.small_main_image}`"
          :alt="item.product_specification.product.name"
        />
      </b-link>
    </div>
    <b-card-body>
      <div class="item-name">
        <h6 class="mb-0">
          <b-link class="text-body">
            {{ item.product_specification.product.name }} {{ item.product_specification.product.brand_name }}
          </b-link>
        </h6>
        <span class="item-company font-medium-2">
          Размер: &nbsp;&nbsp;<span class="text-uppercase">{{ item.product_specification.size_name }}</span>
        </span>
      </div>
      <span class="details-title mt-1">
        <span class="font-weight-bold">Состав: </span><br />
        <span class="font-small-3">{{ item.product_specification.product.full_materials }}</span>
      </span>
      <span class="details-title mb-1">
        <span class="font-weight-bold">Мерки: </span><br />
        <span class="font-small-3">{{ item.product_specification.full_measurements }}</span>
      </span>
      <div class="item-quantity">
        <span class="quantity-title">Количество: </span>
        <b-form-spinbutton v-model="item.qty" size="sm" class="ml-75" inline @change="changeQty(item.id, item.qty)" />
      </div>
      <b-button variant="danger" class="mt-1 mr-3 ml-3 remove-wishlist" @click="removeFromCart(item.id)">
        <feather-icon icon="XIcon" class="mr-50"/>
        <span>Удалить из корзины</span>
      </b-button>
    </b-card-body>
    <div class="item-options text-center">
      <div class="item-wrapper">
        <div class="item-cost">
          <h4 class="item-price">{{ item.cost }} грн</h4>
        </div>
      </div>
      <b-button
        v-if="items.includes(item)"
        variant="light"
        class="mt-1 remove-wishlist"
        @click="removeFromOrder(item.id)"
      >
        <feather-icon icon="XIcon" class="mr-50"/>
        <span>Удалить</span>
      </b-button>
      <b-button
        v-else
        variant="primary"
        class="mt-1 remove-wishlist"
        @click="addToOrder(item)"
      >
        <feather-icon icon="PlusIcon" class="mr-50"/>
        <span>Добавить</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BFormSpinbutton,
  BImg,
  BLink,
} from 'bootstrap-vue';

export default {
  name: 'CheckoutItem',
  components: {
    BCard,
    BCardBody,
    BButton,
    BLink,
    BImg,
    BFormSpinbutton,
  },
  props: ['item', 'items'],
  data() {
    return {
      multiSelectDictionaries: {},
    };
  },
  mounted() {
    this.getMultiSelects();
  },
  methods: {
    getMultiSelects() {
      const multiSelects = this.item.product_specification.product.product_dictionaries.filter((dictionary) => dictionary.product_option
        && dictionary.product_option.input_type === 'multi_select');

      multiSelects.forEach((dictionary) => {
        if (!(dictionary.product_option.slug in this.multiSelectDictionaries)) {
          this.multiSelectDictionaries[dictionary.product_option.slug] = [];
        }
        this.multiSelectDictionaries[dictionary.product_option.slug].push({
          name: dictionary.dictionary.name,
          description: dictionary.description,
          toString() { return `${dictionary.description}% ${dictionary.dictionary.name}`; },
        });
      });
    },
    removeFromOrder(orderItemId) {
      this.$emit('removeFromOrder', orderItemId);
    },
    async addToOrder(orderItem) {
      this.$emit('addToOrder', orderItem);
    },
    async changeQty(orderItemId, qty) {
      this.$emit('changeQty', orderItemId, qty);
    },
    async removeFromCart(orderItemId) {
      this.$emit('removeFromCart', orderItemId);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
